import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import CartierCategoryBtns from '../../components/preowned/CartierCategoryBtns'

// markup
const CartierDiabolo = () => {
  const data = useStaticQuery(
    graphql`
      query queryCartierDiabolo {
        products: allStrapiProduct(
          filter: { brand: { eq: "Cartier" }, model: { regex: "/Diabolo/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Cartier Diabolo Watches for Sale'}
      canonical={'/fine-watches/cartier/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Cartier Diabolo watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/cartier/filter/model/diabolo/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-cartier-diabolo-header.png"
                alt="Pre-Owned Certified Used Cartier Diabolo Header"
                aria-label="Used Cartier  Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">USED CARTIER DIABOLO</h1>
            <h2>
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE AND MODERN CARTIER DIABOLO
              WATCHES AT GRAY AND SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED CARTIER PASHA WATCHES WITH GRAY AND
              SONS JEWELERS
            </h3>
            <p>
              Cartier, as a French luxury brand, is famous for the outstanding, timeless design and
              highest quality. For those interested in iconic square Cartier watches, there are such
              models as Tank or Santos. However, the brand is also well-known for its beautiful
              round timepieces. One of them is Cartier Diabolo, which is a much desired model for
              every luxury watch lover. It is not only a beautiful luxury watch from one of the
              world’s most famous brands, but also a great investment. If you want to buy pre-owned
              Cartier Diabolo, sell pre-owned Cartier Diabolo, trade pre-owned Cartier Diabolo, or
              repair pre-owned Cartier Diabolo watch, check our vast inventory of Cartier Diabolo
              pre-owned watch, Cartier Diabolo estate watch, Cartier Diabolo vintage watch, Cartier
              Diabolo antique watch, Cartier Diabolo heirloom watch, and Cartier Diabolo used watch.
              You can buy Preowned Cartier Diabolo Watch for the best price at Gray &amp; Sons,
              known as the top luxury watch seller in the U.S. Huge selection of fine Swiss watches,
              pre-owned, and estate Cartier watches. Exceptional prices, and a large, in-stock
              inventory of Rolex watches. Looking to buy used Cartier Diabolo, sell used Cartier
              Diabolo, trade used Cartier Diabolo, or repair used Cartier Diabolo? Certified
              pre-owned Cartier watches are now available at Gray &amp; Sons with a top
              selection of used Cartier watches, including pre-owned, estate, vintage Diabolo
              watches. We offer certified pre-owned watches.
              <br />
              <br />
              Popular Cartier Diabolo Watch Models
              <br />
              <ul>
                <li>Cartier Diabolo Ref. W1507551 Quartz in Yellow Gold</li>
                <li>Cartier Diabolo Ref. 14200 Quartz in Yellow Gold</li>
                <li>Cartier Diabolo Ref. 1470 Quartz in Yellow Gold</li>
                <li>Cartier Diabolo Ref. 1460 Mechanical in Yellow Gold</li>
              </ul>
              <br />
              <br />
              Buy pre-owned Cartier Diabolo, sell pre-owned Cartier Diabolo, trade pre-owned Cartier
              Diabolo, or repair pre-owned Cartier Diabolo watches at Gray &amp; Sons. A fine
              Cartier Diabolo model for sale from our inventory is the Cartier Diabolo Ref. 1440.
              This watch is made in 18k yellow gold with crown and lugs cabochon sapphire. On a 18k
              bracelet with an 18k hidden buckle. This Cartier watch has a 27.5 x 27.5mm case with a
              Round caseback and White Roman Numeral dial. It is Gray and Sons Certified Pre-Owned
              and comes backed by our 12-month warranty.
              <br />
              <br />
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED CARTIER WATCHES
              <br />
              <br />
              Is it a good idea to buy pre-owned Cartier Diabolo, sell pre-owned Cartier Diabolo,
              trade pre-owned Cartier Diabolo, or repair pre-owned Cartier Diabolo? Look for the
              best prices and invest - Gray &amp; Sons is a top place to buy used Cartier Diabolo,
              sell used Cartier Diabolo, trade used Cartier Diabolo, or repair used Cartier Diabolo.
              We provide an exclusive in-house warranty for all certified pre-owned
              Cartier watches, including Cartier Diabolo pre-owned watch, Cartier Diabolo estate
              watch, Cartier Diabolo vintage watch, Cartier Diabolo antique watch, Cartier Diabolo
              heirloom watch, and Cartier Diabolo used watch. When it comes to modern Rolex watches,
              we provide a two-year warranty. Gray &amp; Sons only sells watches that have been
              recently serviced, detailed, freshly lubricated, and timing adjusted. At Gray &amp;
              Sons, you’ll find many iconic Cartier wristwatches, including Cartier Diabolo
              pre-owned watch, Cartier Diabolo estate watch, Cartier Diabolo vintage watch, Cartier
              Diabolo antique watch, Cartier Diabolo heirloom watch, and Cartier Diabolo used watch,
              Cartier Tank, Cartier Pasha, Cartier Santos, and Cartier Ballon Bleu, or pre-owned
              Patek Philippe, Audemars Piguet, and Rolex. Make the first step, check out our{' '}
              <a href="/fine-watches/cartier/view-all-cartier-watches/">
                Cartier watch inventory here
              </a>
              and contact our representative for further assistance on{' '}
              <a href="/">www.grayandsons.com</a>. The leading independent, unauthorized Cartier
              watch dealers with inventory control and price flexibility. Just chat now with our
              specialists Rich and Vika via this
              <a href="/chat-with-rich">LINK </a>to make the best deal with our decision-makers. If
              you’d like to meet in person with our highly qualified in-house watchmakers, just
              visit Gray &amp; Sons’ Showroom in Surfside, Miami. Feel free to ask any questions
              about the buying process, prices or details on our pre-owned certified 
              luxury watches. Gray &amp; Sons is the #1 place recommended by our clients to buy
              pre-owned Cartier Diabolo, sell pre-owned Cartier Diabolo, trade pre-owned Cartier
              Diabolo, or repair pre-owned Cartier Diabolo watch.
              <br />
              <br />
              What’s my watch worth? Evaluate your watch at Sell Us Your Jewelry and sell your lux
              watch. Sell Us Your Jewelry is Gray &amp; Sons buying division and has become the
              leading watch buyer and the top-rated place to sell your watch Miami and sell your
              jewelry Miami, Florida. Visit{' '}
              <a href="https://sellusyourjewelry.com/">www.sellusyourjewelry.com</a>
              , the online jewelry buyer and online watch buyer where you can sell used Cartier
              Diabolo, trade used Cartier Diabolo, buy used Cartier Diabolo, or repair used Cartier
              Diabolo at Gray &amp; Sons. We are a professional watch repair shop offering Cartier
              watch battery replacement for certain models along with many other services. If you’re
              asking where can I sell my used watch or where can I sell my jewelry, Gray &amp; Sons
              is the place where you can buy used Cartier Diabolo, sell used Cartier Diabolo, trade
              used Cartier Diabolo, or repair used Cartier Diabolo, and sell your watch or sell your
              jewelry for cash today. Sell Us Your Jewelry is rated by the clients as the #1 watch
              buyer for offering the highest prices in the shortest time for luxury jewelry watches.
              The #1 best choice when you want to buy pre-owned Cartier Diabolo, sell pre-owned
              Cartier Diabolo, trade pre-owned Cartier Diabolo, or repair pre-owned Cartier Diabolo
              watch.
              <br />
              <br />
              Gray &amp; Sons is rated by our customers as the #1 Miami used watch buyer and seller
              in Miami, Miami Beach, Coconut Grove, South Beach, Bal Harbour, Surfside, Sunny Isles,
              Coral Gables, Aventura, Fort Lauderdale, Brickle, Pinecrest, and many more areas in
              Florida. For over 42 years, Gray &amp; Sons has been serving thousands of buyers and
              sellers of pre-owned Cartier Diabolo watches. A professional luxury watch and jewelry
              store located in Surfside, Miami, open six days a week with highly qualified watch and
              jewelry experts on-site available for our customers.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/cartier/">
                <button>SHOP CARTIER WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK CARTIER WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <CartierCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default CartierDiabolo
